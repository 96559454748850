/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import fetch from "node-fetch";
import config from "../../../app-config";
import Button from "./Button";
import TextInput from "./TextInput";
import ChoiceInput from "./ChoiceInput";
import TextAutoGrow from "./TextAutoGrow";
import { Headline, Text } from "../text";
import { useState } from "react";

const READY = 0,
    PENDING = 1,
    FINISHED = 2;

export default function InquiryForm() {
    const [formState, setFormState] = useState(READY);
    // const [formState, setFormState] = useState(FINISHED);
    async function handleSubmit(e) {
        if (e) {
            e.preventDefault();
        }
        setFormState(PENDING);
        const fieldsToSend = ["name", "email", "message"];
        const form = e.target;
        const fields = [
            ...form.querySelectorAll(
                fieldsToSend.map(s => `[name=${s}]`).join(", ")
            )
        ];
        const body = {};
        fields.forEach(f => (body[f.name] = f.value));

        // check if mail is valid

        // try {
        //     const emailCheck = await fetch(
        //         `${config.nameApiUrl}?apiKey=${config.nameApiKey}&emailAddress=${data.email}`
        //     );

        //     if (emailCheck.disposable === "NO") {
        //         return false;
        //     }
        // } catch (error) {
        //     console.log(error);
        // }
        const url = form.action;
        const method = form.method;
        const headers = {
            "Content-Type": "application/json"
        };
        console.log(form, body);
        await fetch(url, { method, headers, body: JSON.stringify(body) }).then(
            res => {
                if (res.url && res.url.indexOf("thank-you") > -1) {
                    setFormState(FINISHED);
                }
            }
        );
    }

    return formState === FINISHED ? (
        <div
            css={css`
                text-align: center;
                margin: 8rem 0 0;
            `}
        >
            <Headline
                as="span"
                role="img"
                ariaLabel="Thumb up!"
                css={css`
                    display: block;
                    margin-bottom: 0.35em;
                `}
            >
                👍
            </Headline>
            <Headline as="div">You are fucking awesome!</Headline>
            <Text>We'll get back to you as soon as we can.</Text>
        </div>
    ) : (
        <form
            acceptCharset="UTF-8"
            action={config.getformUrl + config.getformKey}
            method="POST"
            onSubmit={handleSubmit}
            autoComplete="off"
        >
            <TextInput
                label="Name"
                type="text"
                name="name"
                required="required"
                css={css`
                    margin-bottom: 1em;
                `}
            />
            <TextInput
                label="Email"
                type="email"
                name="email"
                required="required"
                css={css`
                    margin-bottom: 1em;
                `}
            />
            <TextAutoGrow
                label="Message"
                name="message"
                required="required"
                css={css`
                    margin-bottom: 2em;
                `}
            />
            <ChoiceInput
                label="I am giving my consent to the processing of personal
                        data for the purpose of contacting me."
                required="required"
                css={css`
                    margin-bottom: 2em;
                `}
            />
            <Button type="submit" disabled={!!formState}>
                Send
            </Button>
        </form>
    );
}
