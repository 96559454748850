import styled from "@emotion/styled";
import theme from "../../styles/theme";

const Button = styled.button`
    position: relative;
    text-transform: uppercase;
    text-decoration: none;
    border: 0;
    display: inline-block;
    padding: 0.85em 2.5em;
    letter-spacing: 0.35em;
    font-weight: 700;
    font-size: 0.769em;
    cursor: pointer;
    color: currentColor;
    background-color: transparent;
    outline: 0;

    &::before,
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border: 2px solid currentColor;
        z-index: 1;
    }
    &::before {
        transition: transform 0.25s;
    }
    &::after {
        border-color: ${theme.colors.spot1};
        z-index: 0;
        transition: all 0.25s;
    }
    &:not(:disabled):hover,
    &:not(:disabled):focus {
        &::before {
            transform: translate3d(-5px, -5px, 0);
        }
        &::after {
            border-width: 8px;
            width: calc(100% + 0.5em);
            height: calc(100% + 0.5em);
        }
    }

    &:disabled {
        color: ${theme.colors.disabled};
        cursor: default;
    }
`;

// const Button = styled.button`
//     position: relative;
//     text-transform: uppercase;
//     text-decoration: none;
//     border: 0;
//     display: inline-block;
//     padding: 0.85em 0;
//     letter-spacing: 0.35em;
//     font-weight: 700;
//     font-size: 0.769em;
//     cursor: pointer;
//     color: currentColor;
//     background-color: transparent;
//     outline: 0;
//     transition: letter-spacing 0.35s ease-out;

//     &::after {
//         content: "→";
//         margin-left: 0.7em;
//     }
//     &:not(:disabled):hover,
//     &:not(:disabled):focus {
//         letter-spacing: 0.4em;
//     }

//     &:disabled {
//         color: ${theme.colors.disabled};
//         cursor: default;
//     }
// `;

// const Button = styled.button`
//     position: relative;
//     text-transform: uppercase;
//     text-decoration: none;
//     border: 0;
//     display: inline-block;
//     padding: 0.85em 2.5em;
//     letter-spacing: 0.35em;
//     font-weight: 700;
//     font-size: 0.769em;
//     cursor: pointer;
//     color: currentColor;
//     background-color: ${theme.colors.spot1};
//     outline: 0;
//     border-radius: 1em;

//     &::after {
//         content: "→";
//         margin-left: 0.7em;
//     }
//     &:not(:disabled):hover,
//     &:not(:disabled):focus {
//         // letter-spacing: 0.4em;
//     }

//     &:disabled {
//         color: ${theme.colors.disabled};
//         cursor: default;
//     }
// `;

export default Button;
