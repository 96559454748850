import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import theme from "../styles/theme";
import { Smaller } from "../components/text";

const CaseDescription = styled.span(
    css`
        position: absolute;
        padding: ${theme.fluidGrid.mdToX5l.gutter};
        margin: -0.4em 0;
        z-index: 10;
        transition: opacity 0.35s;
    `,
    props => `
        ${props.position.map(p => `${p}: 0;`).join(" ")}
        opacity: ${props.playing ? 0.0001 : 1};
        `
);

const CaseVideo = styled.video(
    css`
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        transition: opacity 0.2s;
        pointer-events: none;
        background-color: ${theme.colors.background};
    `,
    props => `opacity: ${props.playing ? 1 : 0.0001};`
);

const Image = styled(Img)`
    pointer-events: none;
`;

function VideoTile({
    color,
    bgColor,
    videoSrc,
    imageSrc,
    imageAlt,
    description: { title, text, position = ["left", "top"] }
}) {
    const video = useRef();
    const [playing, setPlaying] = useState(false);

    const pause = (promise, video) => {
        const pauseCmds = () => {
            video.pause();
            video.currentTime = 0;
        };
        if (promise) {
            promise.then(_ => pauseCmds());
        } else {
            pauseCmds();
        }
    };

    useEffect(() => {
        const vid = video.current;
        let playPromise;
        if (playing && vid.paused) {
            vid.currentTime = 0;
            playPromise = vid.play();
        } else if (!playing && !vid.paused) {
            if (playPromise) {
                playPromise.then(_ => pause(playPromise, vid));
            } else {
                pause(playPromise, vid);
            }
        }
        return () => {
            pause(playPromise, vid);
        };
    }, [playing]);

    const startVideo = e => {
        e.stopPropagation();
        setPlaying(true);
    };
    const stopVideo = e => {
        e.stopPropagation();
        setPlaying(false);
    };
    return (
        <div
            style={{
                color: color,
                backgroundColor: bgColor
            }}
            onMouseEnter={startVideo}
            onMouseLeave={stopVideo}
            role="figure"
        >
            <Image fluid={imageSrc} alt={imageAlt} />
            <CaseVideo
                src={videoSrc}
                ref={video}
                playing={playing}
                muted
                onEnded={stopVideo}
            />
            <CaseDescription position={position} playing={playing}>
                {title}
                <br />
                <Smaller
                    css={css`
                        display: block;
                        margin-top: -0.2em;
                    `}
                >
                    {text}
                </Smaller>
            </CaseDescription>
        </div>
    );
}

VideoTile.propTypes = {
    color: PropTypes.string,
    bgColor: PropTypes.string,
    videoSrc: PropTypes.string.isRequired,
    imageSrc: PropTypes.object.isRequired,
    imageAlt: PropTypes.string,
    description: PropTypes.object.isRequired
};
VideoTile.defaultProps = {
    color: "inherit",
    bgColor: "transparent",
    imageAlt: ""
};

export default VideoTile;
